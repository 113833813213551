import React, { useState, useEffect } from 'react';
import whitelist from './data/whitelist.json';
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

export function ellipseAddress(
    address = "",
    width = 4
) {
    return `${address.slice(0, width + 2)}...${address.slice(-width)}`;
}

export function useTimer() {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [saleStarted, setSaleStarted] = useState(true);

    const timestamp0 = 1688742000;

    setInterval(() => {
        const timestamp = Math.floor(new Date().getTime() / 1000);
        const diff = timestamp0 - timestamp;
        const d = Math.floor(diff / 86400);
        const h = Math.floor((diff - d * 86400) / 3600);
        const m = Math.floor((diff - d * 86400 - h * 3600) / 60);
        const s = Math.floor(diff - d * 86400 - h * 3600 - m * 60);

        if (d === 0 && h ===0 && m === 0 && s === 0 ){
            setSaleStarted(true);
        } else {
            setSaleStarted(false);
        }

        setDays(d);
        setHours(h);
        setMinutes(m);
        setSeconds(s);
    }, 1000);

    return [days, hours, minutes, seconds, saleStarted];
}

export function formatTimer(number) {
    return number >= 10 ?
        number.toString() :
        '0' + number.toString()

}

export function getWhitelistProof(address) {
    const whitelistMerkleTree = new MerkleTree(whitelist.map((address) => keccak256(address)), keccak256, { sortPairs: true });
    const whitelistedUserLeaf = keccak256(address);
    const whitelistMerkleProof = whitelistMerkleTree.getHexProof(whitelistedUserLeaf);
    return whitelistMerkleProof;
}

export function checkIfWhitelisted(address) {
    return whitelist.includes(address);
}