import React from 'react'
import styled from 'styled-components/macro'

const SocialLink = styled.a.attrs({
    className: 'flex flex-col justify-center w-6 h-6 max-870:w-10 max-870:h-10 bg-light-purple text-center rounded-full hover:opacity-75',
    target: '_blank',
    rel: 'noopener noreferrer'
})``

const SocialIcon = styled.span.attrs({
    className: 'text-white text-sm max-870:text-2xl cursor-pointer'
})``

export default function Footer() {
    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-col max-870:max-w-470px w-full pt-20 max-870:pt-8 pb-8 max-870:pb-12 text-gray-400 border-t border-gray-700">
                <div className="mb-4">
                    <img style={{ height: "53px" }} src="/images/logo.svg" alt="logo" />
                </div>

                <div className="grid grid-cols-2 max-870:grid-cols-1">
                    <div>
                        <p className="w-4/5 max-870:w-full text-lg">
                            Admeal is a platform that leverages Web3 technologies to reinvent online recipies and cooking.
                        </p>
                    </div>
                    <div className="grid grid-cols-5 gap-y-3 max-870:hidden">
                        <a href="/" rel="noopener noreferrer" target="_blank">About us</a>
                        <a href="/" rel="noopener noreferrer" target="_blank">Platform</a>
                        <a href="/" rel="noopener noreferrer" target="_blank">NFT</a>
                        <a href="/" rel="noopener noreferrer" target="_blank">Roadmap</a>
                        <a href="/" rel="noopener noreferrer" target="_blank">Team</a>
                        <a href="/" rel="noopener noreferrer" target="_blank">F.A.Q.</a>
                        <a href="https://docsend.com/view/e8nr3k4reniffett " rel="noopener noreferrer" target="_blank">Whitepaper</a>
                    </div>
                </div>

                <div className="mt-16 max-870:mt-12 mb-24 max-870:mb-12">
                    <h1 className="text-lg text-white font-family-futura font-medium">Contact us</h1>
                    <a href='mailto:support@admeal.xyz' target={'_blank'}><p>support@admeal.xyz</p></a>
                </div>

                <div className="grid grid-cols-2 max-870:grid-cols-1 max-870:items-center max-870:gap-y-5">
                    <div className="flex max-870:justify-center space-x-3 max-870:space-x-5">
                        <SocialLink href='https://twitter.com/Admeal_Official '>
                            <SocialIcon>
                                <i className='fab fa-twitter'></i>
                            </SocialIcon>
                        </SocialLink>
                        <SocialLink href='https://discord.gg/admeal '>
                            <SocialIcon>
                                <i className='fab fa-discord'></i>
                            </SocialIcon>
                        </SocialLink>
                        <SocialLink href='https://www.linkedin.com/company/admeal/'>
                            <SocialIcon>
                                <i className='fab fa-linkedin-in'></i>
                            </SocialIcon>
                        </SocialLink>
                        <SocialLink href='https://medium.com/@admeal '>
                            <SocialIcon>
                                <i className='fab fa-medium-m'></i>
                            </SocialIcon>
                        </SocialLink>
                    </div>
                    <div className="flex justify-between">
                        <a className="max-870:text-center" href="/" rel="noopener noreferrer" target="_blank">Privacy policy</a>
                        <a className="max-870:text-center" href="/" rel="noopener noreferrer" target="_blank">Terms of services</a>
                        <a className="max-870:text-center" href="/" rel="noopener noreferrer" target="_blank">@Admeal 2023</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
