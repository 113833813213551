import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import Header from '../components/header'
import Footer from '../components/footer'
import Home from './Home'

const AppWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 2rem;
  background-image: url('/images/bg-desktop.png');
  background-size: cover;
  overflow: hidden;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-items: center;
  max-width: 1171px;
  width: 100%;
  z-index: 0;
`

const HomeWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`

const MachineLogoLeft = styled.img.attrs({
  src: '/images/machine-left.png',
  alt: 'machine'
})`
  position: absolute;
  width: 400px;
  left: -240px;
  top: 150px;
  z-index: 0;

  @media only screen and (max-width: 875px) {
    display: none;
  }
`

const MachineLogoRight = styled.img.attrs({
  src: '/images/machine-right.png',
  alt: 'machine'
})`
  position: absolute;
  width: 400px;
  right: -12%;
  z-index: -1;

  @media only screen and (max-width: 875px) {
    display: none;
  }
`

const ArrowUp = styled.button`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: white solid 2px;
  outline: none;
  color: white;
  cursor: pointer;
  border-radius: 9999px;
`

const ArrowUpIcon = styled.img.attrs({
  src: '/images/arrow-up.svg',
  alt: 'arrow'
})``

function App() {
  const [isVisible, setIsVisible] = useState(false)

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, [])

  return (
    <AppWrapper>
      <MachineLogoLeft />
      <InnerWrapper>
        <Header />
        <HomeWrapper>
          <Home />
        </HomeWrapper>
        <FooterWrapper>
          <MachineLogoRight />
          <Footer />
        </FooterWrapper>
      </InnerWrapper>
      { isVisible && (
        <ArrowUp onClick={goTop}>
          <ArrowUpIcon />
        </ArrowUp>
      )}
    </AppWrapper>
  );
}

export default App;
