import React, { Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from "@heroicons/react/outline"
import styled from 'styled-components/macro'

const Wrapper = styled.div`
    background-image: url('/images/wallet-modal-bg.png');
    background-size: cover;
    overflow: hidden;
`

function WalletModal({
    isOpen,
    closeModal,
    handleConnect
}) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0 scale-100"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80" />
                    </Transition.Child>
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Wrapper className="inline-block w-full max-w-470px overflow-auto text-left align-middle transition-all transform rounded-xl px-7 py-7 shadow-lg">
                            <Dialog.Title
                                as="h3"
                                className="flex justify-between text-2xl text-white"
                            >
                                Connect wallet
                                <button>
                                    <XIcon
                                        className="inline h-6 text-gray-300 hover:text-white"
                                        onClick={closeModal} />
                                </button>
                            </Dialog.Title>
                            <div className="w-full flex flex-col space-y-4 pt-7 pb-12">
                                <div className="w-full flex items-center space-x-12 pl-2">
                                    <img className="w-11 h-fit" src="/images/metamask.png" alt="img" />
                                    <button className="flex-grow bg-light-gray bg-opacity-40 hover:opacity-80 text-gray-400 text-lg rounded-xl py-3" type="button"
                                            onClick={() => handleConnect('injected')}
                                    >
                                        Connect wallet
                                    </button>
                                </div>
                                <div className="w-full flex items-center space-x-12 pl-2">
                                    <img className="w-11 h-fit" src="/images/wallet-connection.png" alt="img" />
                                    <button className="flex-grow bg-light-gray bg-opacity-40 hover:opacity-80 text-gray-400 text-lg rounded-xl py-3" type="button"
                                            onClick={() => handleConnect('Wallet Connect')}
                                    >
                                        Connect wallet
                                    </button>
                                </div>
                            </div>
                            <p className="text-white text-sm">
                                By connecting a wallet, you agree to Terms of Service and consent to its Privacy Policy
                            </p>
                        </Wrapper>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default WalletModal
