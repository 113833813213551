import React from 'react'
import ReactDOM from 'react-dom'

import App from './pages/App'
import reportWebVitals from './reportWebVitals'

import 'react-toastify/dist/ReactToastify.css';
import './index.css'


import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import {Web3ReactProvider} from '@web3-react/core'
import getLibrary from './utils/getLibrary'

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <App/>
    </Web3ReactProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
