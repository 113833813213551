const NFT = require("./abi/nft.json");
const Noodle = require("./abi/noodle.json");

export const CONTRACTS = {
  ['NFTContract']: {
    137: {address: "0xc4c9fa8396f3712451dDff7A667c086570fd7E15", abi: NFT},
    80001: {address: "0xa2E5CC30b6aD3304A96B2cC8832adCA3e316e42D", abi: NFT}
  },
  ['NoodleNFTContract']: {
    137: {address: "0x1155b9F38BeB4fc275A22aE62DE984042A7aB1c9", abi: Noodle},
    80001: {address: "0x1155b9F38BeB4fc275A22aE62DE984042A7aB1c9", abi: Noodle}
  }
}

export const mintPrice = 0.1;

