import React, { useState, useEffect } from 'react'

import WalletModal from '../modals/WalletModal'

import './style.css'

import { useWeb3React } from '@web3-react/core';
import { injected, walletconnector, bsc } from '../../utils/connector';
import {ellipseAddress} from "../../utils/utils";
import { Buffer } from 'buffer';
Buffer.from('anything', 'base64');

export default function Header() {
    const { account, chainId, activate, deactivate } = useWeb3React();
    const [isOpenedWalletModal, setIsOpenedWalletModal] = useState(false)

    const supportNetworkId = 137;

    const openWalletModal = () => {
        setIsOpenedWalletModal(true)
    }

    const closeWalletModal = () => {
        setIsOpenedWalletModal(false)
    }

    const openNav = () => {
        document.getElementById("mySidenav").style.width = "95%";
    }

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
    }

    const handleLogin = async (wname) => {

        if (wname === 'Wallet Connect') {
            activate(walletconnector);
        }else if (wname === 'Binance Wallet') {
            activate(bsc)
        } else {
            await activate(injected);
        }
        closeWalletModal()
    }

    useEffect(() => {
        (async () => {
            if (account && chainId) {
                if (supportNetworkId !== chainId) {
                    if (window.confirm("Your current Network is unsupportable. Would you like to change it") == true) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: '0x' + supportNetworkId.toString(16) }],
                            });
                        } catch (switchError) {
                            // This error code indicates that the chain has not been added to MetaMask.
                            if (switchError.code === 4902) {
                                alert('add this chain id')
                            }
                        }
                    }
                }
            }
        })();
    }, [chainId, account]);

    return (
        <>
            <WalletModal isOpen={isOpenedWalletModal} closeModal={closeWalletModal} handleConnect={handleLogin}/>
            <div className="flex w-full items-center max-870:justify-between py-9">
                <img className="h-52px max-1150:h-10 -mt-3" src="/images/logo.svg" alt="logo" />
                <div className="flex-1 max-870:hidden flex-row text-white max-1150:text-xs pt-3 px-16 max-1150:px-12 space-x-10 max-1150:space-x-6">
                    <a href="/" rel="noopener noreferrer" target="_blank">About us</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">Platform</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">NFT</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">Roadmap</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">Team</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">F.A.Q.</a>
                    <a href="https://docsend.com/view/e8nr3k4reniffett " rel="noopener noreferrer" target="_blank">Whitepaper</a>
                </div>
                <div className="flex space-x-5 items-center">
                    { account ? <button
                        className="max-870:hidden bg-purple hover:opacity-80 text-white text-xl max-1150:text-base px-5 py-2.5 rounded-lg"
                        onClick={deactivate}
                        type="button"
                    >
                        {ellipseAddress(account)}
                    </button>: <button
                        className="max-870:hidden bg-purple hover:opacity-80 text-white text-xl max-1150:text-base px-5 py-2.5 rounded-lg"
                        onClick={openWalletModal}
                        type="button"
                    >
                        Connect wallet
                    </button>}
                    <button className="space-y-2 hidden max-870:block" onClick={openNav}>
                        <div className="w-7 h-0.5 bg-white"></div>
                        <div className="w-7 h-0.5 bg-white"></div>
                    </button>
                    <button className="w-11 h-11 px-2 border-2 border-gray-400 rounded-lg hidden max-870:block" onClick={openWalletModal}>
                        <img className="filter grayscale" src="/images/metamask.png" alt="img" />
                    </button>
                </div>
            </div>

            <div id="mySidenav" className="sidenav">
                <div className="flex justify-between px-12">
                    <div className="text-white text-5xl cursor-pointer" onClick={closeNav}>&times;</div>
                    <img className="h-10" src="/images/logo.svg" alt="logo" />
                </div>
                <div className="flex flex-col text-white text-2xl space-y-8 text-center my-12">
                    <a href="/" rel="noopener noreferrer" target="_blank">About us</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">Platform</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">NFT</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">Roadmap</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">Team</a>
                    <a href="/" rel="noopener noreferrer" target="_blank">F.A.Q.</a>
                    <a href="https://docsend.com/view/e8nr3k4reniffett " rel="noopener noreferrer" target="_blank">Whitepaper</a>
                </div>
                <div className="flex justify-center space-x-5">
                    <a
                        className='flex flex-col justify-center w-50px h-50px bg-light-purple text-center rounded-lg hover:opacity-75'
                        href='/' rel='noopener noreferrer' target="_blank"
                    >
                        <span className="text-white text-lg cursor-pointer">
                            <i className='fab fa-discord'></i>
                        </span>
                    </a>
                    <a
                        className='flex flex-col justify-center w-50px h-50px bg-light-purple text-center rounded-lg hover:opacity-75'
                        href='/' rel='noopener noreferrer' target="_blank"
                    >
                        <span className="text-white text-lg cursor-pointer">
                            <i className='fab fa-twitter'></i>
                        </span>
                    </a>
                    <a
                        className='flex flex-col justify-center w-50px h-50px bg-light-purple text-center rounded-lg hover:opacity-75'
                        href='/' rel='noopener noreferrer' target="_blank"
                    >
                        <span className="text-white text-lg cursor-pointer">
                            <i className='fab fa-linkedin-in'></i>
                        </span>
                    </a>
                </div>
            </div>
        </>
    )
}
