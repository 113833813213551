import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { CONTRACTS, mintPrice } from '../../utils/contracts';
import { useTimer, formatTimer, getWhitelistProof, checkIfWhitelisted } from "../../utils/utils";
import { toast, ToastContainer } from 'react-toastify';


const TimerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 470px;
    width: 100%;
    height: fit-content;
    background: linear-gradient(90deg, #631AFF -5.85%, #010004 60.53%), #D9D9D9;
    border-radius: 12px;
    padding: 1.5rem 1.5rem;

    @media only screen and (max-width: 870px) {
        border-radius: 12px 12px 0px 0px;
    }
`

const H2 = styled.h2.attrs({
    className: 'text-5xl max-570:text-28px font-family-neue__bold'
})``

const Description = styled.p.attrs({
    className: 'text-xt max-570:text-7px font-light'
})``

export default function Home() {
    const [mintAmount, setMintAmount] = useState(1);
    const { account, library, chainId, } = useWeb3React();
    const [loading, setLoading] = useState(false);
    const [whitelistMintable, setWhitelistMintable] = useState(false);
    const [days, hours, minutes, seconds, saleStarted] = useTimer();
    const [discountSaleAmount, setDiscountSaleAmount] = useState(0);
    const [discountMintable, setDiscountMintable] = useState(false);
    const [noodleHoldIf, setNoodleHoldIf] = useState(false);

    useEffect(() => {
        console.log('account changed');
        if (!account) return;
        initFunc();
    }, [account]);

    const initFunc = async () => {
        const web3 = new Web3(library.provider);
        const metadata = CONTRACTS['NFTContract'][chainId]?.abi;
        const addr = CONTRACTS['NFTContract'][chainId]?.address;

        const NFT = new web3.eth.Contract(metadata, addr);
        const Noodle = new web3.eth.Contract(CONTRACTS['NoodleNFTContract'][chainId]?.abi, CONTRACTS['NoodleNFTContract'][chainId]?.address)

        if (checkIfWhitelisted(account)) {
            const whitelistMinted = await NFT.methods.whitelistMinted(account).call();
            if (whitelistMinted) {
                toast.info("You're whitelisted but already minted a NFT for free")
                setWhitelistMintable(false);
            } else {
                setWhitelistMintable(true);
            }
        }

        const noodleBal = await Noodle.methods.balanceOf(account).call();
        console.log('noodleBal' , noodleBal, noodleBal >= 1)

        const noodleHolderIf = noodleBal >= 1;
        setNoodleHoldIf(noodleHolderIf);
        if (noodleHolderIf) {
            const discount_supply = await NFT.methods.DISCOUNT_SUUPLY().call();
            const discount_sale = await NFT.methods.discount_sale().call();
            const amount = discount_supply - discount_sale;
            console.log('discount-----', discount_supply, discount_sale, amount)
            setDiscountSaleAmount(amount);
            setDiscountMintable(mintAmount <= amount);
        } else {
            setDiscountMintable(false);
        }

    }

    const whitelistMint = async () => {
        if (!account) {
            toast.warn("Please connect wallet!")
            return;
        }
        if (chainId !== 0x13881 && chainId !== 0x89) {
            toast.warn("Please switch to Polygon Network!")
            return;
        }

        const merkleProof = getWhitelistProof(account);
        try {
            setLoading(true);
            const web3 = new Web3(library.provider);
            const metadata = CONTRACTS['NFTContract'][chainId]?.abi;
            const addr = CONTRACTS['NFTContract'][chainId]?.address;

            const NFT = new web3.eth.Contract(metadata, addr);
            await NFT.methods.whitelistMint(merkleProof).send({ from: account });
            toast.success(`Congratulations! You purchased ${mintAmount} NFTs as a whitelisted user`)
            initFunc();
        } catch (e) {
            console.log(e)
            if (e?.message) {
                toast.info(e.message)
            }
        } finally {
            setLoading(false);
        }

    }

    const discountMint = async () => {
        if (!account) {
            toast.warn("Please connect wallet!")
            return;
        }
        if (chainId !== 0x13881 && chainId !== 0x89) {
            toast.warn("Please switch to Polygon Network!")
            return;
        }

        try {
            setLoading(true);
            const web3 = new Web3(library.provider);
            const metadata = CONTRACTS['NFTContract'][chainId]?.abi;
            const addr = CONTRACTS['NFTContract'][chainId]?.address;

            const NFT = new web3.eth.Contract(metadata, addr);
            const price = parseFloat((await NFT.methods.MINT_PRICE_DISCOUNT().call()) / Math.pow(10, 18));
            console.log('price----', price)
            await NFT.methods.discountMint(mintAmount).send({ from: account, value: web3.utils.toWei((mintAmount * price).toString(), "ether")  });
            toast.success(`Congratulations! You purchased ${mintAmount} NFTs on a Discounted Price `)
            initFunc();
        } catch (e) {
            console.log(e)
            if (e?.message) {
                toast.info(e.message)
            }
        } finally {
            setLoading(false);
        }

    }

    const mintFunc = async () => {
        console.log('account', account, chainId)
        if (!account) {
            toast.warn("Please connect wallet!")
            return;
        }
        if (chainId !== 0x13881 && chainId !== 0x89) {
            toast.warn("Please switch to Ppolygon Network!")
            return;
        }
        try {
            setLoading(true);
            const web3 = new Web3(library.provider);
            const metadata = CONTRACTS['NFTContract'][chainId]?.abi;
            const addr = CONTRACTS['NFTContract'][chainId]?.address;

            const NFT = new web3.eth.Contract(metadata, addr);
            const price = parseFloat((await NFT.methods.MINT_PRICE().call()) / Math.pow(10, 18));
            await NFT.methods.mint(mintAmount).send({ from: account, value: web3.utils.toWei((mintAmount * price).toString(), "ether") });
            toast.success(`Congratulations! You purchased ${mintAmount} NFTs`)
        } catch (e) {
            console.log(e)
            if (e?.message) {
                toast.info(e.message)
            }
        } finally {
            setLoading(false);
        }
    }

    const handleIncrease = () => {
        setMintAmount(mintAmount + 1)
        setDiscountMintable(noodleHoldIf && mintAmount <= discountSaleAmount);
    }

    const handleDecrease = () => {
        if (mintAmount === 1) return;
        setMintAmount(mintAmount - 1);
        setDiscountMintable(noodleHoldIf && mintAmount <= discountSaleAmount);
    }

    return (
        <div className="flex flex-col w-full place-items-center items-center pt-12 pb-48 max-870:pb-20">
            <div className="flex flex-row max-870:flex-col w-full items-center space-x-5 max-870:space-x-0 max-870:space-y-5">
                <h1 className="text-5xl max-870:text-3xl max-870:max-w-470px text-white font-bold font-family-neue__black font-bg-gradient-title">
                    Mint NFT and get extra perks for being early backer
                </h1>
                <TimerWrapper>
                <div className="flex flex-col text-white items-center space-y-0.5overflow-hidden">
                            <H2>Sale In Progress</H2>
                        </div>

                    {/* {saleStarted ?
                        <div className="flex flex-col text-white items-center space-y-0.5overflow-hidden">
                            <H2>Sale In Progress</H2>
                        </div>
                        :
                        <>
                            <div>
                                <p className="text-gray-300 border-b border-gray-300 text-xt max-570:text-8px pb-1 pr-7">SALE STARTS IN</p>
                            </div>
                            <div className="flex w-fit space-x-3">
                                <div className="flex flex-col text-white items-center space-y-0.5 w-16 overflow-hidden">
                                    <H2>{formatTimer(days)}</H2>
                                    <Description className="">DAYS</Description>
                                </div>
                                <div className="flex flex-col text-white items-center space-y-0.5 w-16 overflow-hidden">
                                    <H2>{formatTimer(hours)}</H2>
                                    <Description className="text-xt font-light">HOURS</Description>
                                </div>
                                <div className="flex flex-col text-white items-center space-y-0.5 w-16 overflow-hidden">
                                    <H2>{formatTimer(minutes)}</H2>
                                    <Description className="text-xt font-light">MINUTES</Description>
                                </div>
                                <div className="flex flex-col text-white items-center space-y-0.5 w-16 overflow-hidden">
                                    <H2>{formatTimer(seconds)}</H2>
                                    <Description className="text-xt font-light">SECONDS</Description>
                                </div>
                            </div>
                        </>

                    } */}

                </TimerWrapper>
            </div>

            <div className="grid grid-cols-2 max-870:flex max-870:flex-col-reverse max-870:items-center w-full mt-20 max-870:mt-0">
                <div className="flex flex-col max-870:max-w-470px max-870:w-full text-gray-400 pt-10">
                    <div className="max-870:hidden">
                        <h1 className="text-5xl text-white font-family-neue__bold">Utilities:</h1>
                        <p className="mt-8 mb-24 text-xl">
                            3% more DISH tokens for each reward<br></br>
                            3% discount on in-app purchases<br></br>
                            DISH and ADM token drops<br></br>
                            NFT drops from CPG brands and future collections <br></br>
                            NFT gate access to premium recipes from chefs<br></br>
                            Up to 5 NFTs can be merged for increased benefits<br></br>
                            Exclusive advantages<br></br>
                        </p>
                    </div>
                    <div className="flex flex-col w-full space-y-3">
                        <div className="w-full max-870:flex max-870:justify-between max-870:items-center">
                            <div className="text-lg">3000 pcs/ 48 MATIC</div>
                            <div className="hidden max-870:flex items-center space-x-3 text-2xl">
                                <button onClick={handleDecrease}>-</button>
                                <input className="w-10 h-10 bg-transparent text-center px-2 py-2 border border-gray-500 text-base rounded-lg focus:outline-none"
                                    value={mintAmount}
                                    onChange={ev => setMintAmount(ev.target.value)}
                                    disabled
                                />
                                <button onClick={handleIncrease}>+</button>
                            </div>
                        </div>
                        <div className="w-full flex items-center space-x-7">
                            {whitelistMintable ?
                                <button className="max-w-310px max-870:max-w-full w-full bg-light-gray hover:opacity-80 text-lg font-family-neue__bold rounded-xl py-3"
                                    type="button"
                                    onClick={whitelistMint}
                                    disabled={loading}
                                >
                                    WhiteList Mint
                                </button> : 
                                discountMintable ?
                                    <button className="max-w-310px max-870:max-w-full w-full bg-light-gray hover:opacity-80 text-lg font-family-neue__bold rounded-xl py-3"
                                        type="button"
                                        onClick={discountMint}
                                        disabled={loading}
                                    >
                                        Discount MINT
                                    </button> :
                                    <button className="max-w-310px max-870:max-w-full w-full bg-light-gray hover:opacity-80 text-lg font-family-neue__bold rounded-xl py-3"
                                        type="button"
                                        onClick={mintFunc}
                                        disabled={loading}
                                    >
                                        MINT NFT
                                    </button>
                            }

                            <div className="flex items-center space-x-3 text-2xl max-870:hidden">
                                <button onClick={handleDecrease} disabled={whitelistMintable}>-</button>
                                <input className="w-14 h-12 bg-transparent text-center px-2 py-2 border border-gray-500 text-base rounded-full focus:outline-none"
                                    value={mintAmount}
                                    onChange={ev => setMintAmount(ev.target.value)}
                                    disabled
                                />
                                <button onClick={handleIncrease} disabled={whitelistMintable}>+</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex place-content-end">
                    <video className="rounded-2xl max-870:rounded-none max-870:rounded-b-2xl max-870:max-w-470px max-870:w-full" autoPlay muted loop>
                        <source src="/media/intro.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <ToastContainer theme={'dark'} position={'top-right'} />
        </div>
    )
}
